body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #2a4c4b; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiSwitch-thumb {
  width: 20px;
  height: 20px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 50%;
  background-image: linear-gradient(296deg, #ff69c5, #a767ff);
}
.MuiBackdrop-root {
  backdrop-filter: blur(4px);
}
.MuiLinearProgress-barColorPrimary {
  /* background-color: #51d4d8 !important; */
  background-image: linear-gradient(
    296deg,
    #ff69c5,
    #51d4d8,
    #a767ff
  ) !important;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating-image {
  animation: rotate 10s linear infinite; /* Adjust the duration as needed */
  transform-origin: center;
}



:root {
  /* Glitter Color */
  --clr-purple: #ff69c5;
  --clr-purple-lt: #f5d7e9;

  /* Dark Mode Color */
  --clr-drkx: #000000;
  --clr-dkr: #222121;
  --clr-dk: rgb(44, 43, 43);
  --clr-d: #3c3c3c;
  --clr-dmc: #2a4c4b;

  /* Light Mode Color */
  --clr-ltrx: #ffffff;
  --clr-lt: #f9f9f9;
  --clr-l: #bebebe;
  --clr-lmclr: rgba(118, 208, 213, 0.15);

  /* Primary Color */
  --clr-yellow-lt: #fff9b2;
}
